<template>
   <v-container>
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <h1 class="milk-collection">Customer Receipt</h1>
      <v-btn color="info" small dense class="ma-1" to="/dairy/collect">
        <v-icon>mdi-backburger</v-icon>
      </v-btn>
    </div>

    {{ todaysCollection }}

       
    <div class="custom-input mb-3">
      <v-combobox
        class="form-input"
        v-model="farmer"
        :search-input.sync="search"
        :items="farmers"
        item-text="farmerSearch"
        return-object
        outlined
        label="Farmer*"
        color="#00AFA5"
        required
        style="min-width: 100px; max-width: 100%;"
        hint="*Required."
        persistent-hint
      />
    </div>
    <v-row>
      <v-col>
        <v-btn
        class="submit-n-print col-6"
        :class="valid ? 'enabled-button' : 'disabled-button'"
        @click="singleRecept"
        block
        >
          Reprint Receipt
        </v-btn>
      </v-col>
      <v-col v-if="isOnline">
        <v-btn
          class="submit-n-print col-6"
          :class="valid? 'enabled-button' : 'disabled-button'"
          @click="printCustomerReceipt"
          block
        >
          Print Statement 
        </v-btn>
      </v-col>
    </v-row>
   </v-container>
  
</template>

<script>
import DateInput from "@/components/DateInput";
import PrintLayoutMixin from "@/mixins/PrintLayoutMixin";
import date from "@/mixins/date";
import AppMenuItem from '@/components/AppMenuItem'
import lookups from "@/mixins/lookups";
import receiptPrint from "@/mixins/receiptPrint"

export default {
  components: {DateInput, AppMenuItem},

  data() {
    return{
      search: null,
      farmers: [],
      farmer: null,
      receiptStatement: null,
      receiptDetails: null,
      cumulative: null,
      uuid: null,
      collectorSettings: undefined,
    }
  },

  computed: {
    valid() { return  ( (this.search || '').length>2 && this.farmer  ) },
    userName() { return this.$store.getters.settings.name; },
    farmerTodaysLastCollection() { return this.$store.getters.todaysDeliveries.filter(e => e.customerUuid === this.farmer.uuid ) },
    isOnline() { return this.$store.getters.networkOnline; },
    cachedFarmers() { return this.$store.getters.customers }
  },

  watch: {

    search(to) {
      this.retrieveFarmers();
    },

    farmer(to) {
      if (to && typeof(to)==='object') {
        console.log("to: ", to)
        this.remoteLookup('dairyFarmerCumulativeMilk', { uuid: to.uuid }).then( milk => {
          console.log("milk: ", milk)
          console.log("milk.kg", milk.kg)
          this.cumulative = milk.kg
          console.log("cummulative is set: ", this.cumulative)
        } ).catch(e => {
            let dairyFarmer = this.$store.getters.customer(to.uuid)
            console.log("dairy_farmer: ", dairyFarmer)
            if (dairyFarmer) {
              this.cumulative = this.cumulative || (dairyFarmer.dairyFarmerCumulativeMilk || {}).kg
            }
          });
        this.uuid = to.uuid
      }
    },

    uuid: {
        immediate: true,
        handler(to) {
          this.$store.dispatch('addCustomersToCache', [to]);
        },
      },

  },

  methods: {
    // async retrieveFarmers () {
    //   this.searchOffLine()
    //   this.remoteLookup('searchDairyFarmers', { search: this.search }).then( results => this.farmers = (this.farmers || []).concat(results) )
    //   this.farmers = (this.farmers || []).concat(this.filteredOffline)
    // },

    async retrieveFarmers () {
      // this.searchOffLine()
      this.farmers = this.cachedFarmers
      this.farmers = this.farmers.filter(e => (e.farmerSearch.toLowerCase()).match(this.search.toLowerCase()))
      if(this.farmers.length === 0 && this.isOnline) {
        this.remoteLookup('searchDairyFarmers', { search: this.search }).then( results => this.farmers = (this.farmers || []).concat(results) )
      }
    },

    fieldAgentInfo() {
      let depotId = this.$store.getters.settings.fieldAgentDepotId
      let depotName = (this.$store.getters.settings.depots.filter(e => e.id==depotId )[0] || {}).name
      let fieldAgentPayload = this.$store.getters.settings.field_agent_payload
      let route = fieldAgentPayload.route
      this.collectorSettings = {route: route, processor: depotName }
    },

    // searchOffLine() {
    //   this.filteredOffline = this.$store.getters.customers
    //   // this.filteredOffline = this.filteredOffline.map(item => ({...item, search: (item.name + '/' + item.registrationNumber)}))
    //   // this.filteredOffline = this.filteredOffline.filter(e => (e.name || '').toLowerCase().match(this.search.toLowerCase()) != null || (e.registration_number || '').match(this.search) != null || (e.msisdn || '').match(this.search) != null)
    //   this.filteredOffline = this.filteredOffline.filter(e => (e.farmerSearch.toLowerCase()).match(this.search.toLowerCase()))
    // },

    printCustomerReceipt() {
      console.log("cumulative before print: ", this.cumulative)
      let farmer = this.farmer
      let cumulative = this.cumulative
      let deliveries_list = []
      this.remoteLookup('milkReceiptReprint', { farmer: this.farmer.uuid }).then( results => {this.receiptStatement = results})
      .then(() => {
          console.log("cumulative: ", cumulative)
          deliveries_list.push(["Date", "Session", "Qnt"])
          let receiptPrintData = this.receiptStatement.data
          let agent = this.receiptStatement.agent
          let processor = this.receiptStatement.processor_name
          receiptPrintData.forEach(element => {  
            console.log("element: ", element)
            // time = `${element.getDate()}-${element.getMonth() + 1}-${element.getFullYear}`
            let rr = [new Date(element.time).toLocaleDateString('de-DE'), element.session, `${element.quantity} kg`]
            deliveries_list.push(rr)
          });
          this.printReceipt(farmer={farmer: farmer.name,farmerNo: (farmer.registration_number || farmer.registrationNumber), agent: agent, processor: processor, cumulative: cumulative, receiptType: "Mini Statement Report"}, deliveries_list)
      })

      this.farmer = undefined;
      this.cumulative = undefined;
      this.farmerMilkWeight = undefined
    },
    singleRecept(){
      let details
      console.log("farmer collection: ", this.farmerTodaysLastCollection)
      if(this.farmerTodaysLastCollection.length > 0) {
        details = this.farmerTodaysLastCollection[0]
      }
      this.remoteLookup('singleReceiptReprint', { farmer: this.farmer.uuid }).then( results => this.receiptDetails = results)
      .then(() => {
        details = this.receiptDetails.data
      })
      let farmer = this.farmer
      let receiptDetails = {}
        if(Object.values(details || {}).length === 0){
          receiptDetails = {message: "this farmer has not made any Deliveries todays."}
        if(!isOnline){
          receiptDetails = receiptDetails.concat(" ", "go online to get his last delivery receipt")
        }
      } else {
          receiptDetails = {
          Delivered: `${details.quantity || details.weight} kg`,
          "Receipt No": details.receipt_number,
          Session: details.session || details.amPm,
          Route: details.route,
          Time: details.time || details.createdAt,
          "Can No": details.can_number || details.canNumber,
        }
      }
      console.log("partner settings: ", this.collectorSettings)
      this.printReceipt(farmer={farmer: farmer.name, farmerNo: (farmer.registration_number || farmer.registrationNumber), agent: this.userName, cumulative: this.cumulative || details.cumulative, processor: (this.collectorSettings.processor || (this.receiptDetails || {}).processor_name), receiptType: "Milk Reprint Receipt"}, receiptDetails)
    },
  },
  mounted() {
    this.fieldAgentInfo()
    this.farmers = this.$store.getters.customers
    if (this.isOnline) {
      // console.log(console.log("farmers: ", this.farmers))
    }
  },
   mixins: [lookups, PrintLayoutMixin, date, receiptPrint],
}
</script>

<style scoped>
.enabled-button {
  background: #008768 !important; 
  color: var(--primary-blue, #FFFFFF);
}

.disabled-button {
  background: var(--neutrals-pale-grey, #CED3D9) !important;
  color: #5C6C80 !important;
  pointer-events: none; 
}
.milk-collection{
  color: var(--pw-text-colours-gray-medium, #3C4B6C);
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 15px 0px 30px 0px;
  line-height: 16px; /* 114.286% */
}
</style>
