<template>
  <transition>
    <keep-alive>
      <v-card class="pa-2 ma-1 w-100">
        <router-view :class="custom_font ? 'custom-font' : ''"/>
      </v-card>
    </keep-alive>
  </transition>
</template>

<script>
import domain from '@/mixins/domain';

export default {
  computed: {
    custom_font() {
      return (this.domain === 'milk')
    },
  },
  
  mixins: [domain],
}
</script>
