<template>
  <v-container >
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <h1 class="milk-collection mb-2 pb-2">MILK COLLECTION</h1>
      <h5><span>{{ search }}</span></h5>
    </div>
    <div style="display: flex; justify-content: space-between; align-items: center;">
      <v-btn v-if="showDemo" dense small class="demo-btn" plain @click="toggleDemoReadings">{{(btDemoReadings ? 'Stop' : 'Demo')}}</v-btn>
      <v-btn color="info" small dense class="ma-1" to="/dairy/daily_produce">
        DailyProduce
      </v-btn>
      <v-btn color="info" small dense class="ma-1" to="/receipt/milk">
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    </div>
    <div>
      <div class="d-flex flex-column" style="min-height: 78vh;">
        <div>
          <div class="d-flex justify-space-between mb-12" style="width: 100%;"  v-if="isBluetoothAvailable">
            <!-- <template v-if="bluetoothDevice">
              <div class="d-flex justify-space-between" style="width: 100%;">
                  <v-btn
                    class="connected-btn"
                    @click="bluetoothScan"
                    block
                  >
                    <img class="mr-2" src="@/assets/icons/connected_signal_tower.svg" />
                    CONNECTED TO BLUETOOTH
                  </v-btn>
              </div>
            </template>

            <template v-else>
              <div class="d-flex justify-space-between" style="width: 100%;">
                  <v-btn
                    class="connect-btn"
                    @click="bluetoothScan"
                    block
                  >
                    <img class="mr-2" src="@/assets/icons/signal_tower.svg" />
                    CONNECT TO BLUETOOTH
                  </v-btn>
              </div>
            </template> -->

          </div>

          <template v-else>
            <div class="d-flex justify-space-between print-hide">
              <div class="print-hide">Bluetooth is <strong>not</strong> available.</div>
              <div class="print-hide"><v-btn plain @click="toggleDemoReadings" x-small>{{(btDemoReadings ? 'Stop' : 'Demo')}}</v-btn></div>
            </div>
          </template>
          <div class="custom-input mt-2 pt-2">
            <v-combobox
              class="form-input"
              v-model="farmer"
              :search-input.sync="search"
              :items="farmers"
              item-text="farmerSearch"
              return-object
              dense
              outlined
              @change="onChange"
              label="Farmer*"
              color="#00AFA5"
              required
              style="min-width: 100px; max-width: 100%;"
              hint="*Required."
              persistent-hint
            />
          </div>

           <div class="custom-input mb-3">
            <v-select
              class="form-input"
              v-model="session"
              :items="[1,2,3]"
              item-value="1"
              dense 
              outlined
              label="Session*"
              ref="textInputRef"
              color="#00AFA5"
              required
              style="min-width: 100px; max-width: 100%;"
              hint="*Required."
              persistent-hint
            />
          </div>

          <div>
            <div class="mb-3 d-flex justify-space-between">
              <v-text-field
                  class="form-input"
                  label="Can Number*"
                  color="#00AFA5"
                  hide-details="auto"
                  dense 
                  outlined
                  v-on:change="onCanInputChange"
                  v-model.lazy="canNumber"
                  clearable
                  hint="*Required."
                  persistent-hint
                  :counter="10" 
                  :class="{ 'custom-input-filled': canNumber }"
              ></v-text-field>
            </div>
          </div>

          <div>
            <div v-if="manualWeightInput === true" class="mb-3 d-flex justify-space-between">
              <v-text-field
                  class="form-input"
                  label="Milk Weight*"
                  color="#00AFA5"
                  hide-details="auto"
                  dense
                  outlined
                  v-model="farmerMilkWeight"
                  clearable
                  hint="*Required."
                  type="number"
                  persistent-hint
                  :counter="10" 
                  :class="{ 'custom-input-filled': canNumber }"
              ></v-text-field>
            </div>
          </div>
          <v-row v-if="manualWeightInput === false"  class="measurement-section mb-4">
            <v-col cols="6">
              <div class="box-item text--secondary">
                <div class="left-aligned">Farmer</div>
                <div class="left-aligned hyphene">
                  {{ farmerScaleValue.toFixed(1) > 0.0 ? `${farmerScaleValue.toFixed(1)} kg` :  '-' }}
                </div>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="box-item text--secondary">
                <div class="left-aligned">Scale</div>
                <div class="left-aligned hyphene">
                  {{ btWeighScaleRawValue.toFixed(1) == 0.0 ? '-' : `${btWeighScaleRawValue.toFixed(1)} kg` }}
                </div>
              </div>
            </v-col>
          </v-row>

          </div>

        <template class="text-center pa-0 ma-0">
          <div>
            <v-dialog v-model="dialog" width="auto" height="300px">
              <v-card class="align-center justify-center">
                <v-card-title class="align-center justify-center">
                  <v-icon color="red" size="38px">mdi-information-outline</v-icon>
                </v-card-title>
                <v-card-title class="align-center justify-center">
                  Warning
                </v-card-title>
                <v-card-text >
                  <p>Produce for mno-{{(farmer || {}).registration_number}} Session {{session}} already exists</p>
                </v-card-text>
                <v-card-actions class="align-center justify-center">
                  <v-btn @click="dialog = false" color="green">Ok</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </template>

        <template class="mb-0 pb-0">
          <div class="text-center pa-0 ma-0">
            <v-dialog v-model="selectDialog" width="auto" height="300px">
              <v-card class="align-center justify-center">
                <v-card-title class="align-center justify-center">
                  <v-icon color="red" size="38px">mdi-information-outline</v-icon>
                </v-card-title>
                <v-card-title class="align-center justify-center">
                  Warning
                </v-card-title>
                <v-card-text >
                  <p>Farmer not selected!!!</p>
                </v-card-text>
                <v-card-actions class="align-center justify-center">
                  <v-btn @click="selectDialog = false" color="green">Ok</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </template>

        <div>
          <v-btn
            class="submit-n-print mt-0 pt-0"
            :class="valid ? 'enabled-button' : 'disabled-button'"
            @click="submitAndPrint"
            block
          >
            Submit and print
          </v-btn>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import PrintLayoutMixin from "@/mixins/PrintLayoutMixin";
import lookups from "@/mixins/lookups";
import scale from "@/mixins/scale";
import date from "@/mixins/date";
import PageHeader from "@/components/PageHeader";
import Time from "@/components/Time";
import NumberFormatter from "@/components/NumberFormatter";
import receiptPrint from "@/mixins/receiptPrint"


export default {

  components: { PageHeader, Time, NumberFormatter },

  data() {
    return {
      search: null,
      farmers: [],
      farmer: null,
      showDemo: true,
      cumulative: null,
      collectorSettings: undefined,
      characteristic: null,
      device:       null,
      canNumber: null,
      farmerMilkWeight: null,
      manualWeightInput: true,
      sessionTime: new Date(),
      session: 1,
      sessions: [1,2,3],
      dialog: false,
      selectDialog: false,
      sessionTaken: false,
      timer: null,
      lastReading: null,
      receiptNumber: (uuidv4().split("-")[0]),
      filteredOffline: null,
      uuid: null,
    }
  },

  computed: {
    signOutPopUp() { return this.$store.getters.signOutPopUp; },
    userName() { return this.$store.getters.settings.name; },
    valid() { return ( (!!this.canNumber) && ( (this.search || '').length>2 && (this.btWeighScaleRawValue || (this.farmerMilkWeight && this.farmerMilkWeight > 0)) && this.sessionTaken === false ) ) },
    amPm() { return (this.sessionTime.getHours() >= 12) ? "PM" : "AM"; },
    farmerScaleValue() { return (this.btWeighScaleRawValue - this.lastReading); },
    fieldAgentId() { return this.$store.getters.settings.fieldAgentId; },
    route() { return ( this.collectorSettings ? this.collectorSettings.route : undefined ) },
    location() { return ((this.$store.getters.geolocation || {}).coords); },
    userName() { return this.$store.getters.settings.name; },
    isOnline() { return this.$store.getters.networkOnline; },
    isDeviceConnected() {if (this.bluetoothDevice) {this.manualWeightInput = false}; return this.manualWeightInput},
    cachedFarmers() { return this.$store.getters.customers }
  },


  watch: {

    search(to) {
      this.retrieveFarmers();
    },

    farmer(to) {
      console.log("customer: ", to)
      this.cumulative = undefined;
      if (to && typeof(to)==='object') {
        this.remoteLookup('dairyFarmerCumulativeMilk', { uuid: to.uuid }).then( milk => {
          console.log("milk: ", milk)
          this.cumulative = milk.kg
        } ) .catch(e => {
            let dairyFarmer = this.$store.getters.customer(to.uuid)
            if (dairyFarmer) {
              this.cumulative = this.cumulative || (dairyFarmer.dairyFarmerCumulativeMilk || {}).kg
            }
          });
        this.uuid = to.uuid
        this.checkSession(this.session)
      }
    },

    session(to) {
      if(this.farmer) {
        this.checkSession(to)
      }
    },
    
    uuid: {
      immediate: true,
      handler(to) {
        this.$store.dispatch('addCustomersToCache', [to]);
      },
    },

  },

  methods: {
    closeDialog() {
      this.$store.dispatch('updatesignOutPopUp');
    },

    signOut() {
      this.closeDialog()
      this.$store.dispatch('logout');
      this.$router.push({name: 'Home'});
    },

    toggleDemoReadings() {
      this.btDemoReadings = !this.btDemoReadings;
      this.manipulateBluetoothDevice()
    },

    onCanInputChange(val) {
      this.lastReading = 0
      this.clearScaleValue()
    },

    onChange() {
      this.$refs.textInputRef.focus();
    },

    checkSession(to) {
      console.log("todays: deliveries: ", this.$store.getters.todaysDeliveries)
      let farmerSessions = this.$store.getters.checkSession(this.farmer.uuid)
      let sessionTaken = farmerSessions.filter(e => e.SessionNumber === to)
      if((sessionTaken || []).length > 0) {
        this.dialog = true
        this.sessionTaken = true
      } else {
        this.sessionTaken = false
      }
    },

    resetLastReading() {
      this.lastReading = this.btWeighScaleRawValue;
    },

    async retrieveFarmers () {
      // this.searchOffLine()
      console.log("console.log this is offline data: ", this.farmers)
      this.farmers = this.cachedFarmers
      this.farmers = this.farmers.filter(e => (e.farmerSearch.toLowerCase()).match(this.search.toLowerCase()))
      if(this.farmers.length === 0 && this.isOnline) {
        this.remoteLookup('searchDairyFarmers', { search: this.search }).then( results => this.farmers = (this.farmers || []).concat(results) )
      }
    },

    // searchOffLine() {
    //   this.filteredOffline = this.farmers
    //   // this.filteredOffline = this.filteredOffline.map(item => ({...item, search: (item.name + '/' + item.registrationNumber)}))
    //   // this.filteredOffline = this.filteredOffline.filter(e => (e.name || '').toLowerCase().match(this.search.toLowerCase()) != null || (e.registration_number || '').match(this.search) != null || (e.msisdn || '').match(this.search) != null)
    //   this.filteredOffline = this.filteredOffline.filter(e => (e.farmerSearch.toLowerCase()).match(this.search.toLowerCase()))

    // },

    updateReceiptNumber() {
      this.receiptNumber = uuidv4().split("-")[0]

    },

    fieldAgentInfo() {
      let depotId = this.$store.getters.settings.fieldAgentDepotId
      let depotName = (this.$store.getters.settings.depots.filter(e => e.id==depotId )[0] || {}).name
      let fieldAgentPayload = this.$store.getters.settings.field_agent_payload
      let route = fieldAgentPayload.route
      this.collectorSettings = {route: route, processor: depotName }
    },

    submitAndPrint() {
      let farmer = this.farmer
      if(farmer === null || farmer === undefined || typeof(farmer) === 'string') {
        this.selectDialog = true
      } else {
        console.log("farmer: ", this.farmer)
        let farmerMilkSize = this.manualWeightInput ? parseFloat(this.farmerMilkWeight) : this.farmerScaleValue
        let cumulative     = (this.cumulative || 0) + farmerMilkSize
        const submission = {
          uuid: uuidv4(),
          customerUuid: (this.farmer ? this.farmer.uuid : null),
          farmer_reference: ( this.farmer ? this.farmer.name : this.search),
          farmer_search: this.search,
          canNumber: this.canNumber,
          weight: farmerMilkSize,
          cumulative: cumulative,
          farmerNo: this.farmer.registrationNumber,
          amPm: this.amPm,
          SessionNumber: this.session,
          route: this.route,
          farmerName: this.farmer.name,
          location: this.location,
          createdAt: this.sessionTime,
          receipt_number: this.receiptNumber,
          synced: false
        }
        const receiptDetails = {
          Delivered: `${farmerMilkSize} kg`,
          "Receipt No": this.receiptNumber,
          "Session No": this.session,
          Route: this.route,
          Time: this.sessionTime.toLocaleDateString() + ' ' + this.sessionTime.toLocaleTimeString(),
          "Can No": this.canNumber,
        }

        console.time("encusolation");
        this.$store.dispatch('todaysDeliveries', submission).then(e => {
            console.log("submissions: ", submission)
            this.$store.dispatch('milkCollectionUpsert', submission);
            console.time("myFunctionTimer");
            this.printReceipt(farmer={farmer: farmer.name, farmerNo: (farmer.registration_number || farmer.registrationNumber), agent: this.userName, cumulative: cumulative, processor: this.collectorSettings.processor, receiptType: "Milk Collection Receipt"}, receiptDetails)
            console.timeEnd("myFunctionTimer");
          }
        )
        console.timeEnd("encusolation");
        
        this.farmer = undefined;
        this.cumulative = undefined;
        this.farmerMilkWeight = undefined
        this.updateReceiptNumber()

        // this.printMixin().then(() => {

        //   this.farmer = undefined;
        //   this.cumulative = undefined;

        //   this.resetLastReading();
        //   this.updateReceiptNumber()
        // })
      }
    },
  },

  mounted() {
    this.fieldAgentInfo()
    this.timer = setInterval(() => {
      this.sessionTime = new Date();
    }, 1000);
    this.farmers = this.$store.getters.customers
  },

  beforeDestroy() { clearInterval(this.timer) },

  mixins: [lookups, PrintLayoutMixin, scale, date, receiptPrint],
}
</script>

<style scoped>

.yes-btn, .no-btn {
  display: flex;
  width: 293px;
  height: 44px !important;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 900 !important;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
}
.yes-btn {
  background: #008768 !important;
  color: white;
}

.no-btn {
  margin-top: 16px;
  margin-bottom: 24px;
  background: white !important;
  color: #008768;
  border: 1px solid var(--Primary-green, #008768);
}

.milk-collection{
  color: var(--pw-text-colours-gray-medium, #3C4B6C);
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin: 15px 0px 30px 0px;
  line-height: 16px; /* 114.286% */
}

.enabled-button {
  background: #008768 !important; 
  color: var(--primary-blue, #FFFFFF);
}

.disabled-button {
  background: var(--neutrals-pale-grey, #CED3D9) !important;
  color: #5C6C80 !important;
  pointer-events: none; 
}

.required {
  display: flex;
  width: 328px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex-shrink: 0;
  margin-top: -30px;
}

.connected-btn{
  border: 1px solid var(--Alert-green, #00AD6C);
  color: var(--Alert-green, #00AD6C);
}

.connect-btn{
  border: 1px solid var(--Primary-blue, #007D85);
  color: var(--Primary-blue, #007D85);
}

.connect-btn, .connected-btn{
  height: 45px !important;
  border-radius: 8px;
  background: var(--pw-text-colours-white, #FFF) !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 900 !important;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
}

.submit-n-print{
  border-radius: 8px;
  height: 70px !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 900 !important;
  line-height: 16px; /* 114.286% */
  text-transform: uppercase;
}

.custom-input{
  margin-top: 15px;
}
.connected-icon img{
  margin-right: 10px;
}

.box-item {
  width: 100%;
  border-left: 2.5px solid var(--primary-blue, #007D85);
  padding: 10px;
}

.left-aligned {
  text-align: left;
}

.form-input {
  border-radius: 8px;
}

.hyphene{
  color: #222;
  margin-top: 10px;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}



.demo-btn {
  border: 1px solid var(--alert-green, #00AD6C);
}

@media print {
  .receipt-div {
    border-radius: 6px;
    background: var(--neutrals-white, #FFF);
    box-shadow: 0px 0px 3px 0px rgba(34, 34, 34, 0.36);
    display: flex;
    width: 328px;
    flex-direction: column;
    flex-shrink: 0;
    margin: 20px;
  }

  .receipt-title {
    color: var(--neutrals-medium-grey-text-body, #444);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
  }

  .receipt-name {
    color: var(--neutrals-light-grey-text-subdued, #767676);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
  }

  .custom-table tbody tr {
    border-bottom: none;
    border-top: none;
    margin-bottom: 10px;
    width: 100%;

    .title-cell {
      color: var(--neutrals-medium-grey-text-body, #444);
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
    }

    .content-cell {
      color: var(--neutrals-medium-grey-text-body, #444);
      text-align: right;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      text-align: right;
    }
  }
}

</style>
